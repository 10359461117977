export const useDefaultRoute = (homePage?: string): string => {
  switch (homePage) {
    case 'SPEND':
      return '/spend'
    case 'SPEND_OVERVIEW':
      return '/spend'
    case 'CARDS':
      return '/cards'
    case 'PAYOUTS':
      return '/finance/payment-run'
    case 'STATEMENTS':
      return '/finance/statement'
    case 'SPENDREPORTS':
      return '/finance/spend-reports'
    case 'ACCOUNTS_OVERVIEW':
      return '/accounts'
    default:
      return '/'
  }
}
