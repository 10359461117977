import { useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'context/auth/AuthContext'
import { useLogin } from 'templates/login/hooks/useLogin'
import { useSettings } from 'yordex-ui-kit'
import { useDefaultRoute } from 'components/delegates-modal/hooks/useDefaultRoute'

export const useRedirectIfAlreadyAuthorized = () => {
  const { authToken } = useAuth()
  const { session } = useLogin()
  const navigate = useNavigate()
  const { uiSettings } = useSettings()
  const defaultRoute = useDefaultRoute(uiSettings?.homePage)

  useLayoutEffect(() => {
    if (authToken && session && session.traderId) {
      navigate(defaultRoute)
    }
    // Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
