import React from 'react'
import { useField, Form } from 'formik'
import {
  PrimaryButton,
  InputPassword,
  Input,
  FormField,
  Alert,
} from 'yordex-ui-kit'
import styles from './login-form.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'

interface Props {
  submitting: boolean
  submitted: boolean
  errorMessage?: string
  onSubmit: (email: string, password: string) => void
  isMobileNumberVerified?: boolean
  isUISettingsLoading?: boolean
}

export const LoginForm = ({
  submitting,
  submitted,
  errorMessage,
  onSubmit,
  isMobileNumberVerified,
  isUISettingsLoading,
}: Props) => {
  const { t } = useTranslation()
  const [emailField, emailMeta] = useField('email')
  const [passwordField, passwordMeta] = useField('password')

  const handleSubmit = () => {
    onSubmit(emailField.value, passwordField.value)
  }

  return (
    <Form className={styles['login-form']} noValidate>
      {errorMessage && (
        <Alert className={styles['login-form__error-message']} type="error">
          {errorMessage}
        </Alert>
      )}
      {isMobileNumberVerified && (
        <Alert className={styles['login-form__error-message']} type="info">
          {t('logIn.successFullyVerifiedMobileNumber')}
        </Alert>
      )}
      <FormField
        className={styles['login-form__input']}
        id="email"
        label={{ name: t('logIn.form.email') }}
        errorMessage={emailMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
      >
        <Input
          id="email"
          {...emailField}
          large
          required
          type="text"
          disabled={submitting}
          error={submitted && !!emailMeta.error}
        />
      </FormField>
      <FormField
        className={styles['login-form__input']}
        id="password"
        label={{ name: t('logIn.form.password') }}
        errorMessage={passwordMeta.error}
        submitting={submitting}
        submitted={submitted}
        required={true}
      >
        <InputPassword
          id="password"
          {...passwordField}
          type="password"
          large
          required
          passwordToggle={true}
          disabled={submitting}
          error={submitted && !!passwordMeta.error}
        />
      </FormField>
      <PrimaryButton
        type="submit"
        data-testid="log-in-button"
        large
        disabled={isUISettingsLoading || submitting}
        className={styles['login-form__button']}
        onClick={handleSubmit}
      >
        {t('logIn.form.logInBtn')}
      </PrimaryButton>
    </Form>
  )
}
