import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, OptionGroup, ApiSession, Tag, UISettings } from 'yordex-ui-kit'
import { useAuth } from 'context/auth/AuthContext'
import styles from './delegates-modal.module.scss'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { useDefaultRoute } from './hooks/useDefaultRoute'

export interface Props {
  referer?: string
  session: ApiSession
  isOpen: boolean
  close: () => void
  uiSettings?: UISettings
}

const DelegatesModal = ({
  referer,
  session,
  isOpen,
  close,
  uiSettings,
}: Props) => {
  const { t } = useTranslation()
  const { setAuthToken, authToken } = useAuth()
  const [token, setToken] = useState(authToken)
  const { delegateTokens } = session
  const navigate = useNavigate()
  const defaultRoute = useDefaultRoute(uiSettings?.homePage)

  const onChange = useCallback(
    (authToken: string) => {
      setToken(authToken)
    },
    [setToken],
  )

  const onLogIn = useCallback(() => {
    setAuthToken(token)
    navigate(referer || defaultRoute)
    close()
  }, [setAuthToken, token, close, navigate, referer, defaultRoute])

  const onClose = useCallback(() => {
    setAuthToken(undefined)
    close()
  }, [setAuthToken, close])

  useEffect(() => {
    setToken(authToken)
  }, [setToken, authToken])

  const options = useMemo(() => {
    if (!delegateTokens) {
      return []
    }

    return delegateTokens
      .sort((d1, d2) => d1.username.localeCompare(d2.username))
      .map((d) => ({
        key: d.token,
        label: d.username,
      }))
  }, [delegateTokens])

  useEffect(() => {
    if (isOpen && !delegateTokens) {
      navigate(referer || defaultRoute)
    }
  }, [isOpen, delegateTokens, navigate, referer, defaultRoute])

  return (
    <Modal
      className={styles['delegates-modal']}
      isOpen={isOpen}
      close={onClose}
      primaryButtonText={t('delegates.submitBtn')}
      onPrimaryButtonClick={onLogIn}
      title={t('delegates.title')}
    >
      <OptionGroup<string>
        value={token}
        optionsClassName={styles['delegates-modal__options']}
        description={t('delegates.optionsLabel')}
        onChange={onChange}
        options={[
          {
            key: session.token,
            label: (
              <div className={styles['delegates-modal__user-option']}>
                <p className={styles['delegates-modal__user-email']}>
                  {session.username}
                </p>
                <Tag small>{t('delegates.youTag')}</Tag>
              </div>
            ),
          },
          ...options,
        ]}
      />
    </Modal>
  )
}

export default DelegatesModal
