import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { object } from 'yup'
import { Link, useModal, LanguageSelector, useSettings } from 'yordex-ui-kit'
import PublicLayout from 'components/public-layout/PublicLayout'
import {
  emailValidationSchema,
  passwordValidationSchema,
} from 'utils/validation/user'
import { LoginForm } from './login-form/LoginForm'
import { useLogin } from './hooks/useLogin'
import DelegatesModal from 'components/delegates-modal/DelegatesModal'
import SocialLoginButtons from './social-login-buttons/SocialLoginButtons'
import styles from './login.module.scss'
import { useRedirectIfAlreadyAuthorized } from 'hooks/useRedirectIfAlreadyAuthorized/useRedirectIfAlreadyAuthorized'
import { useTranslation } from 'hooks/useTranslation/useTranslation'
import { LoginOtp } from './login-otp/LoginOtp'
import { VerifyPhoneNumber } from './verify-phone-number/VerifyPhoneNumber'
import { useLocation } from 'react-router-dom'
import { useGetLocationLanguage } from './hooks/useGetLocationLanguage'
import { fetchSettings } from 'server/fetch/settings/fetch'

export interface FormValues {
  email: string
  password: string
}

/* eslint-disable prettier/prettier */
const validationSchema = object().shape({
  email: emailValidationSchema,
  password: passwordValidationSchema,
})
/* eslint-enable prettier/prettier */

const Login = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { referer, email = '', password = '', phoneVerified = false } =
    state || {}
  useRedirectIfAlreadyAuthorized()
  const {
    isLoggedIn,
    handleLogin,
    handleOtpLogin,
    handleGoogleLogin,
    handleMicrosoftLogin,
    googleSessionError,
    googleTokenId,
    microsoftSessionError,
    microsoftTokenId,
    uniqueNonce,
    loginErrorMessage,
    otploginErrorMessage,
    session,
  } = useLogin()

  const { isOpen, close, open } = useModal()
  const [submitted, setSubmitted] = useState(false)
  const [username, setUsername] = useState('')
  const [passcode, setPasscode] = useState('')
  const [isMobileNumberVerified, setIsMobileNumberVerified] = useState(false)
  const { locationLanguage } = useGetLocationLanguage()
  const storedLanguage = localStorage.getItem('language')
  const selectedLanguage = storedLanguage || locationLanguage
  if (!storedLanguage && locationLanguage) {
    localStorage.setItem('language', locationLanguage)
  }
  // const { uiSettings } = useCommonData()
  const { uiSettings, setUISettings } = useSettings()
  const [isUISettingsLoading, setIsUISettingsLoading] = useState<boolean>(false)

  useEffect(() => {
    async function fetchSettingsData() {
      if (isLoggedIn && !isOpen) {
        if (session) {
          setIsUISettingsLoading(true)
          const { traderId, roles } = session
          const { uiSettings } = await fetchSettings(traderId, roles)
          if (uiSettings) {
            setUISettings(uiSettings)
            setIsUISettingsLoading(false)
            open()
            return uiSettings
          }
        }
      }
    }
    fetchSettingsData()
  }, [isLoggedIn, open, isOpen, session, setUISettings])

  useEffect(() => {
    if (phoneVerified) {
      setIsMobileNumberVerified(true)
    }
  }, [phoneVerified])

  const handleOnSubmit = (email: string, password: string) => {
    setUsername(email)
    setPasscode(password)
    setSubmitted(true)
  }

  if (
    session &&
    session.otpInfo &&
    (session.otpInfo.otpStatus === 'SMS_SENT' ||
      session.otpInfo.otpStatus === 'EMAIL_SENT')
  ) {
    return (
      <LoginOtp
        session={session}
        otploginErrorMessage={otploginErrorMessage}
        handleOtpLogin={handleOtpLogin}
        username={username}
        passcode={passcode}
        googleTokenId={googleTokenId}
        microsoftTokenId={microsoftTokenId}
        uniqueNonce={uniqueNonce}
      />
    )
  } else if (
    session &&
    session.otpInfo &&
    (session.otpInfo.otpStatus === 'MISSING_PHONE_NUMBER' ||
      session.otpInfo.otpStatus === 'PHONE_NUMBER_NOT_VERIFIED')
  ) {
    return <VerifyPhoneNumber session={session} />
  } else {
    return (
      <PublicLayout
        languageSelector={<LanguageSelector value={selectedLanguage || 'EN'} />}
        title={t('logIn.logIn')}
        subtitle={
          <>
            {t('logIn.inviteReceived')}
            <Link
              className={styles['login__register-password-link']}
              href="/forgot-password"
            >
              {t('logIn.registerPassword')}
            </Link>
          </>
        }
        footer={
          <SocialLoginButtons
            handleGoogleLogin={handleGoogleLogin}
            googleSessionError={googleSessionError}
            handleMicrosoftLogin={handleMicrosoftLogin}
            microsoftSessionError={microsoftSessionError}
          />
        }
      >
        <>
          <Formik<FormValues>
            initialValues={{ email, password }}
            onSubmit={handleLogin}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <LoginForm
                submitting={isSubmitting}
                submitted={submitted}
                errorMessage={loginErrorMessage}
                onSubmit={handleOnSubmit}
                isMobileNumberVerified={isMobileNumberVerified}
                isUISettingsLoading={isUISettingsLoading}
              />
            )}
          </Formik>
          <Link
            href="/forgot-password"
            className={styles['login__forgot-password-link']}
          >
            {t('logIn.forgotPassword')}
          </Link>
        </>

        {session && session.traderId && isLoggedIn && (
          <DelegatesModal
            close={close}
            isOpen={isOpen}
            session={session}
            referer={referer}
            uiSettings={uiSettings}
          />
        )}
      </PublicLayout>
    )
  }
}

export default Login
